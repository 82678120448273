import React, { useEffect, useState } from "react";
import MultiSelectAll from "../Components/MultiSelectAll";
import "./Notify.css";
var selectedUsersIds = [];

export default function Notify() {
  var [Users, setUsers] = useState(null);
  var [message, setMessage] = useState(null);
  var [usertype, setUserType] = useState(null);
  var [selectedUsers, setSelectedUsers] = useState();
  console.log(usertype);

  const getUserData = (json) => {
    var res = [];
    json.forEach((e) => {
      res.push({
        id: e.id,
        value: e.id,
        label: e.fullName,
      });
    });
    //    console.log(res);
    return res;
  };
  const getUsers = async () => {
    try {
      const response = await fetch(window.baseurl + "admin/get-users", {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      getUserData(json);

      setUsers(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  const sendNotify = async () => {
    try {
      const response = await fetch(
        window.baseurl + "api/Notifs/sendnotification",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
            userIdz: [selectedUsersIds],
            body: message,
          }),
        }
      );
      const json = await response.json();
      console.log(json);
      // navigate("/admin/admins");
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (value) => {
    value = value.map((e) => e.value !== "*");
    selectedUsersIds = value.map((e) => e.id).join(",");
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div className="layout-spacing">
        <div className="col-">
          <h2 className="textOrange">إشعار لمستخدم واحد</h2>
        </div>
        <div className="statbox">
          <div className="widget-content widget-content-area">
            {/* <form onSubmit={postForm}> */}
            <form>
              <div className="py-2 row">
                <div className="col-9">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="رسالة إعلامية"
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </div>
                <div className="col-3">
                  <div>
                    {Users && (
                      <MultiSelectAll
                        classNames={{
                          select: "form-control",
                        }}
                        handleChange={handleChange}
                        data={getUserData(Users)}
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      />
                    )}
                  </div>
                  <div className=" py-4">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setUserType(e.target.value);
                      }}
                    >
                      <option>حدد نوع المستخدم</option>
                      <option value={"users"}>المستخدمين</option>
                      <option value={"drivers"}> السائقين </option>
                      <option value={"providers"}> مطابخ </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
            <button
              onClick={(e) => {
                sendNotify();
              }}
              type="submit"
              className="btn btn-success col-2"
            >
              يرسل
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
