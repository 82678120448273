import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import "./App.css";
import Dashboard from "./Pages/Dashboard";
import Home from "./Pages/Home";

//Auth
import Login from "./Pages/Login";

//Admins
import Admins from "./Pages/Admins";
import AddAdmin from "./Pages/AdminAdd";
//Drivers
import Drivers from "./Pages/Drivers";
import DriverDetails from "./Pages/DriverDetails";
import DriverRegister from "./Pages/RegisterDriver";

//Providers
import Providers from "./Pages/Providers";
import ProviderDetails from "./Pages/ProviderDetails";
import ProviderRegister from "./Pages/RegisterProvider";

//Foods
import Foods from "./Pages/Foods";
import FoodDetail from "./Pages/FoodDetail";
import FoodList from "./Pages/FoodList";
import FoodEdit from "./Pages/FoodEdit";

//Orders
import Orders from "./Pages/Orders";
import OrderDetail from "./Pages/OrderDetail";

//Accounts
import Accounts from "./Pages/Accounts";
import AccountDetail from "./Pages/AccountDetail";

//Transactions
import Transactions from "./Pages/Transactions";
import TransactionDetails from "./Pages/TransactionDetails";

//Roles
import Roles from "./Pages/Roles";
import hasRole from "./Functions";

//Settings
import SuccessPage from "./Pages/success";

//Fields
import Fields from "./Pages/Fields";
import FieldAdd from "./Pages/FieldAdd";
import FieldEdit from "./Pages/FieldEdit";

//Notifications
import Notify from "./Pages/Notify";

//Charts
import Settings from "./Pages/Settings";
import Support from "./Pages/Support";
import ContactUs from "./Pages/ContactUs";
import TabsView from "./Components/TabsView";
import NotPermited from "./Pages/NotPermited";
import CusinePage from "./Pages/CusinePage";
import CusineMeals from "./Pages/CusineMeals";
import MailsList from "./Pages/MailsList";

function App() {
  // if (window.location.href.includes("localhost")) {
  // //window.baseurl = "http://localhost:5009"
  // //window.baseurl = "https://localhost:44308/"
  //   window.baseurl = "https://dev.webapi.matbakh24.com/";
  // }
  // else
  // {
    window.baseurl = "https://webapi.matbakh24.com/";
  //}
  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="driver/signup" element={<DriverRegister />} />
        <Route path="cusine/signup" element={<ProviderRegister />} />
        <Route path="success" element={<SuccessPage />} />
        {/* <Route path="support" element={hasRole("support")?<Support />:<Login error={true}/>} /> */}

        {/* outlet */}
        <Route path="admin" element={<Dashboard />}>
          {/* <Route path="" element={hasRole("home")?isMobile?<MobileHome/>:<Home />:<Login error={true}/>} />
      <Route path="home" element={hasRole("home")?isMobile?<MobileHome/>:<Home />:<Login error={true}/>} /> */}
          <Route
            path=""
            element={hasRole("home") ? <Home /> : <Login error={true} />}
          />
          <Route
            path="home"
            element={hasRole("home") ? <Home /> : <Login error={true} />}
          />
          <Route
            path="orders"
            element={
              hasRole("orders") ? <Orders /> : <NotPermited error={true} />
            }
          />
          <Route
            path=""
            element={hasRole("home") ? <Home /> : <NotPermited error={true} />}
          />
          <Route
            path="notifications"
            element={hasRole("notifications") ? <Notify /> : <NotPermited error={true} />}
          />
          <Route
            path="Fields"
            element={hasRole("Fields") ? <Fields /> : <NotPermited error={true} />}
          />
          <Route
            path="field/Add"
            element={hasRole("Fields") ? <FieldAdd /> : <NotPermited error={true} />}
          />
          <Route
            path="Field/Edit"
            element={hasRole("Fields") ? <FieldEdit /> : <NotPermited error={true} />}
          />
          <Route
            path="home"
            element={hasRole("home") ? <Home /> : <NotPermited error={true} />}
          />

          <Route path="order/detail" element={<OrderDetail />} />
          <Route
            path="accounts"
            element={hasRole("accounts") ? <Accounts /> : <Login error={true} />}
          />
          <Route
            path="account/Detail"
            element={hasRole("accounts") ? <AccountDetail /> : <Login error={true} />}
          />

          <Route
            path="roles"
            element={hasRole("roles") ? <Roles /> : <Login error={true} />}
          />
          <Route
            path="providers"
            element={
              hasRole("providers") ? <Providers /> : <Login error={true} />
            }
          />
          <Route path="provider/detail" element={<ProviderDetails />} />
          <Route path="provider/meals" element={<MailsList />} />
          {/* <Route path="provider/meals" element={<CusineMeals />} /> */}
          <Route path="driver/detail" element={<DriverDetails />} />
          <Route
            path="drivers"
            element={hasRole("drivers") ? <Drivers /> : <Login error={true} />}
          />
          <Route
            path="admin/add"
            element={
              hasRole("add-manager") ? <AddAdmin /> : <Login error={true} />
            }
          />
          <Route
            path="admins"
            element={hasRole("managers") ? <Admins /> : <Login error={true} />}
          />
          <Route
            path="transactions"
            element={
              hasRole("transactions") ? (
                <Transactions />
              ) : (
                <Login error={true} />
              )
            }
          />
          <Route path="transaction/detail" element={<TransactionDetails />} />
          <Route
            path="foods"
            element={hasRole("foods") ? <Foods /> : <Login error={true} />}
          />
          <Route
            path="food/detail"
            element={hasRole("foods") ? <FoodDetail /> : <Login error={true} />}
          />
          <Route
            path="Provider/Foods"
            element={hasRole("foods") ? <FoodList /> : <Login error={true} />}
          />
          <Route
            path="foods/edit"
            element={hasRole("foods") ? <FoodEdit /> : <Login error={true} />}
          />
          <Route
            path="reports"
            element={hasRole("reports") ? <TabsView /> : <Login error={true} />}
          />
          <Route path="support" element={<Support />} />
          <Route path="settings" element={<Settings />} />
          <Route path="contact" element={<ContactUs />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
