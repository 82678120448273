import { Link, useLocation } from "react-router-dom";
import ImagesGallery from "../Components/ImageGallary";

export default function FoodDetail() {
  const location = useLocation();
  const row = location.state.row;
  //console.log(row);
  return (
    <>
      <div className="layout-spacing row">
        <div className="col-10">
          <h2>تفاصيل الطعام</h2>

          <h1 className="textOrange">{row?.food.name.split("大")[0]}</h1>
        </div>
        <div className="col-2">
          <Link
            to="/admin/foods"
            className="btn btn-outline-primary rounded-circle mx-2"
            title="الرجوع"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </Link>
        </div>
      </div>

      <div id="flLoginForm" className="col-lg-12 layout-spacing">
        <div className="statbox">
          <div className="widget-content widget-content-area">
            <ImagesGallery images={row?.photos.map((img, index) => img.url)} />
          </div>
        </div>
      </div>
      <div id="flLoginForm" className="col-lg-12 layout-spacing">
        <div className="statbox">
          <div className="widget-content  widget-content-area row">
            <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-12  col-12">
              <div className=" col-12 py-2">
                <h5 className="item-title">اسم الوجبة</h5>
                &nbsp;
                <input
                  value={row?.food.name.split("大")[0]}
                  className="textOrange form-control disabled-form-control"
                  disabled
                />
              </div>

              <div className=" col-12 py-2">
                <h5 className="item-title">السعر</h5>
                &nbsp;
                <input
                  className="textOrange form-control disabled-form-control"
                  value={row?.food.price + " ريال "}
                  disabled
                />
              </div>
              <div className=" col-12 py-2">
                <h5 className="item-title"> سعر المطبخ</h5>
                &nbsp;
                <input
                  className="textOrange form-control disabled-form-control"
                  value={row?.food.provider_price + " ريال "}
                  disabled
                />
              </div>
              <div className="col-12 py-2">
                <h5 className="item-title">طريقة التقديم</h5>
                &nbsp;
                <input
                  className="textOrange form-control disabled-form-control"
                  value={row?.food.serve_way.split("大")[0]}
                  disabled
                />
              </div>


              <div className="col-12 py-2">
                <h5 className="item-title">خصم المطبخ</h5>
                &nbsp;
                <input
                  className="textOrange form-control disabled-form-control"
                  value={row?.food.provider_offerPrice + " ريال "}
                  disabled
                />
              </div>

              <div className="col-12 py-2">
                <h5 className="item-title">سعر العرض</h5>
                &nbsp;
                <input
                  className="textOrange form-control disabled-form-control"
                  value={row?.food.offerPrice + " ريال "}
                  disabled
                />
              </div>
              <div className="col-12 py-2">
                <h5 className="item-title">مدة التحضير</h5>
                &nbsp;
                <input
                  className="textOrange form-control disabled-form-control"
                  value={row?.food.preparation_time + " د "}
                  disabled
                />
              </div>
            </div>


            <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="col-12 py-2">
                <h5 className="item-title">الأشخاص</h5>
                &nbsp;
                <input
                  className="textOrange form-control disabled-form-control"
                  value={row?.food.persons + " شخص "}
                  disabled
                />
              </div>
              <div className="col-12 py-2">
                <h5 className="item-title">وصف الوجبة</h5>
                &nbsp;
                <textarea
                  className="form-control disabled-form-control"
                  value={row?.food.desc.split("大")[0]}
                  disabled
                />
              </div>
              <div className="col-12 py-2">
                <h5 className="item-title">الغذاء لعدد الأشخاص المصطلح</h5>
                &nbsp;
                <input
                  className="textOrange form-control disabled-form-control"
                  value={
                    " انش تكفي شخصين" +
                    row?.food.persons +
                    " كيكة ڤنيلا او شكولاته مقاس "
                  }
                  disabled
                />
              </div>

              <div className="col-12 py-2">
                <h5 className="item-title">مرفقات الوجبة</h5>
                &nbsp;
                <textarea
                  className="form-control disabled-form-control"
                  value={row?.food.side}
                  disabled
                />
              </div>
              <div className="col-12 py-2">
                <h5 className="item-title">الملحقات</h5>
                &nbsp;
                <textarea
                  className="form-control disabled-form-control h-100"
                  value={row?.food.notes.split("大")[0]}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
