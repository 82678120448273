import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
var userRoles = "";
export default function Roles() {
  const navigate = useNavigate();
  const location = useLocation();
  const row = location.state?.row;
  console.log(row);
  const postForm = async () => {
    console.log(userRoles);
    const formdata = new FormData();
    formdata.append("userId", row?.id);
    formdata.append("roles", userRoles);
    try {
      const response = await fetch(window.baseurl + "admin/update-roles", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: formdata,
      });
      console.log(response);
      const json = await response.json();
      console.log(json);
      navigate("/admin/admins");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className="layout-spacing row">
        <div className="col-10">
          <h2 className="textOrange">الصلاحيات</h2>
        </div>
        <div className="col-2">
          <Link
            to="/admin/admins"
            className="btn btn-outline-primary rounded-circle mx-2"
            title="الرجوع"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </Link>
        </div>
      </div>

      <div id="flLoginForm" className="col-lg-12 layout-spacing">
        <div className="statbox">
          <div className="widget-content widget-content-area">
            <form onSubmit={postForm}>
              <div className=" role-item">
                <h1 className="my-3">{row?.fullName}</h1>
                <ul className="ks-cboxtags row">
                  <li>
                    <input
                      className="role-input col-6"
                      type="checkbox"
                      defaultChecked={row?.roles?.includes("home")}
                      id="checkboxOne"
                      onChange={(e) => {
                        if (e.target.checked) {
                          userRoles = userRoles + " home";
                        } else {
                          userRoles = userRoles.replace("Home", "");
                        }
                      }}
                    />
                    <label htmlFor="checkboxOne">الرئيسية</label>
                  </li>
                  <li>
                    <input
                      className="role-input  col-6"
                      id="checkboxTwo"
                      // defaultChecked={row?.roles?.includes("accounts")}
                      defaultChecked={row?.roles?.includes("accounts")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          userRoles = userRoles + " accounts";
                        } else {
                          userRoles = userRoles.replace("accounts", "");
                        }
                        // console.log(userRoles);
                      }}
                      type="checkbox"
                    />
                    <label htmlFor="checkboxTwo">المستخدمين</label>
                  </li>
                  <li>
                    <input
                      className="role-input"
                      id="checkboxFour"
                      defaultChecked={row?.roles?.includes("providers")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          userRoles = userRoles + " providers";
                        } else {
                          userRoles = userRoles.replace("providers", "");
                        }
                      }}
                      type="checkbox"
                    />
                    <label htmlFor="checkboxFour">الطباخين</label>
                  </li>
                  <li>
                    <input
                      className="role-input"
                      id="checkboxFive"
                      defaultChecked={row?.roles?.includes("drivers")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          userRoles = userRoles + " drivers";
                        } else {
                          userRoles = userRoles.replace("drivers", "");
                        }
                        //console.log(userRoles);
                      }}
                      type="checkbox"
                    />
                    <label htmlFor="checkboxFive">المناديب</label>
                  </li>
                  <li>
                    <input
                      className="role-input"
                      id="checkboxSix"
                      defaultChecked={row?.roles?.includes("reports")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          userRoles = userRoles + " reports";
                        } else {
                          userRoles = userRoles.replace("reports", "");
                        }
                      }}
                      type="checkbox"
                    />
                    <label htmlFor="checkboxSix">التقارير</label>
                  </li>
                  <li>
                    <input
                      className="role-input"
                      id="checkboxSix"
                      defaultChecked={row?.roles?.includes("notifications")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          userRoles = userRoles + " notifications";
                        } else {
                          userRoles = userRoles.replace("notifications", "");
                        }
                      }}
                      type="checkbox"
                    />
                    <label htmlFor="checkboxSix">إشعارات</label>
                  </li>
                  <li>
                    <input
                      className="role-input"
                      defaultChecked={row?.roles?.includes("orders")}
                      id="checkboxSeven"
                      onChange={(e) => {
                        if (e.target.checked) {
                          userRoles = userRoles + " orders";
                        } else {
                          userRoles = userRoles.replace("orders", "");
                        }
                      }}
                      type="checkbox"
                    />
                    <label htmlFor="checkboxSeven">الطلبات</label>
                  </li>
                  <li>
                    <input
                      className="role-input"
                      id="checkboxEight"
                      defaultChecked={row?.roles?.includes("support")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          userRoles = userRoles + " support";
                        } else {
                          userRoles = userRoles.replace("support", "");
                        }
                      }}
                      type="checkbox"
                    />
                    <label htmlFor="checkboxEight">الشكاوي</label>
                  </li>
                </ul>
              </div>
            </form>
            <Link to="/admin/admins" className="btn btn-danger mt-4 floated">
              إلغاء
            </Link>

            <button
              onClick={(e) => {
                postForm();
              }}
              type="submit"
              className="btn btn-success  mt-4 floated"
            >
              حفظ
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
